<template>
	<div class="public">

		<el-form :rules="dataRules" label-width="140px" class="form-con-center5" :model="form" ref="form" v-loading="dataListLoading">

			<el-form-item label="测评套餐名称" prop="packageName">
				<el-input placeholder="请输入" v-model.trim="form.packageName" class="wd400"></el-input>
			</el-form-item>
			<el-form-item label="缩略图">
				<el-upload style="width: 350px;" class="avatar-uploader" list-type="picture-card" :action="actionUrl"
					:accept="'.jpg, .jpeg, .png, .gif'" :limit="1" :show-file-list="false" :before-upload="beforeUpload"
					:on-success="handleUploadSuccess">
					<img v-if="form.picUrl" :src="form.picUrl" class="avatar">

					<i class="el-icon-plus"></i>
				</el-upload>
			</el-form-item>
			<el-form-item label="套餐内容" required>
				<el-table style="width: 850px;" row-key="index" :Key="key" :data="list" border align="left">
					<el-table-column label="序号" width="80" type="index" :index="indexMethod">
					</el-table-column>
					<el-table-column show-overflow-tooltip v-for="(item, index) in col" :key="`col_${index}`"
						:prop="col[index].prop" :label="item.label">
						<template slot-scope="scope">

							<p v-if="item.prop!='isClick'">{{scope.row[item.prop]}}</p>
							<div v-else>
								<!-- <img style="width: 15px;height: 15px;cursor: pointer;margin-right: 8px;"
									@click="delTb(scope.row,scope.$index)" src="~@/assets/img/jinshen/delete.png"
									alt=""> -->
								<img style="width: 15px;height: 15px;cursor: pointer;"
									src="~@/assets/img/jinshen/paixu.png" alt="">




							</div>
						</template>
					</el-table-column>
				</el-table>
			<!-- 	<div @click="visible = true" class="tianjia">添加</div> -->

			</el-form-item>


			<el-form-item label="状态">
				<el-radio v-model="form.status" :label="1">启用</el-radio>
				<el-radio v-model="form.status" :label="2">禁用</el-radio>
			</el-form-item>
			<el-form-item style="padding-top: 30px">
				<el-button type="primary" @click="submit" v-points>保存</el-button>
				<el-button type="primary" @click="submitNext" v-points>保存并配置</el-button>
				<el-button @click="back">取消</el-button>
			</el-form-item>

		</el-form>

		<el-dialog title="添加套餐内容" :visible.sync="visible" width="800px" :close-on-click-modal="false">
			<el-form>
				 <div style="width:450px; text-align: center;margin: 0 auto;">
				             <el-form-item label="套餐类型" required>
				             	<el-select placeholder="请选择" v-model="contentType" clearable style="width:350px;">
				             		<el-option :value="1" label="AI测评模型"></el-option>
				             		<el-option :value="3" label="舌苔模型"></el-option>
				             		<el-option :value="2" label="表单问卷"></el-option>
				             	</el-select>
				             </el-form-item>
				          
				
				<el-form-item v-if="contentType==1" label="内容名称" required>
					<el-select placeholder="请选择" v-model="relationObj" clearable filterable style="width:350px;">
						<el-option v-for="item in dataList" :key="item.id" :label="item.modelName" :value="item">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="contentType==3" label="内容名称" required>
					<el-select value-key = "id"  placeholder="请选择" v-model="relationObj" clearable filterable style="width:300px;">
						<el-option v-for="item in dataList2" :key="item.id" :label="item.modelName" :value="item">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="contentType==2" label="内容名称" required>
					<el-input style="width:350px;" @focus="showChoose()" placeholder="请选择或搜索">
						<i slot="suffix" class="el-input__icon el-icon-arrow-down"></i>
					</el-input>

				</el-form-item>
				  </div>
			</el-form>



			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="addPac">保存</el-button>
				<el-button @click="visible = false">取消</el-button>
			</span>
		</el-dialog>
		<choose-question v-show="qsDialog" ref="chooseQs" @chooseQs="getOther($event)" :type="1"></choose-question>
	</div>

</template>
<script>
	import chooseQuestion from "./components/chooseQuestion.vue";
	import TinyMce from "@/components/tiny-mce";
	import Sortable from 'sortablejs';
	import Qs from "qs";
	import moment from "moment";
	export default {
		name: "publicAdd",
		components: {
			TinyMce,
			chooseQuestion,
			Sortable
		},
		data() {
			return {
				key: 0,
				visible: false,
				edit: false,
				qsDialog: true,
				actionUrl: '',
				importHeader: {
					token: ""
				},
				dataList: [],
				dataList2: [],
				list: [],
				contentType: 1,
					contentTypeArr: ['AI测评模型', '表单问卷','舌苔模型'],

				relationObj: '',
				col: [
					// {
					// 	label: '序号',
					// 	prop: 'orderNum'
					// },
					{
						label: '类型',
						prop: 'contentTypeName'
					}, {
						label: '名称',
						prop: 'relationName'
					},
					{
						label: '操作',
						prop: 'isClick'
					}
				],
				fileList: [],
				fileList2: [],
				checkList: [],
				form: {


					packageName: "",

					picUrl: "",



					status: 1,
				},
				diseasesList: [],
				typeList: [],
				dataRules: {
					packageName: [{
						required: true,
						message: "测评套餐名称不能为空",
						trigger: "blur"
					}, ],


				},
				dataListLoading: false,
				type: "",
			};
		},
		async mounted() {

			this.rowDrop()
			this.getList()
				this.getList2()
			this.importHeader.token = localStorage.token;
			this.actionUrl = this.$global.baseURL + "/aliyun/oss/upload";
			this.form.publishTime = moment().format("YYYY-MM-DD HH:mm:ss");
			let query = this.$route.query;
			if (query.id) {
				this.edit = true;

				await this.init(query.id);
			}
			await this.getTypeList();

		},
		methods: {
			indexMethod(index) {
				return index + 1;
			},
			submit() {

				if (this.list.length == 0) {
					this.$message.warning('请添加套餐内容！')
					return
				}
				this.$refs.form.validate(async (valid) => {
					if (valid) {



						const {
							data: res
						} = await this.$httpAes({
							url: this.$httpAes.adornUrl(
								this.form.id ? "/evaluationPackage/update" :
								"/evaluationPackage/add "
							),
							method: "post",
							contentType: 'json',
							headers: {
								'Content-Type': 'application/json',

							},
							data: this.form,
						});
						console.log(res);
						if (res.status) {
							this.$router.push({
							          name: "evaluationPackageSetting",
							          query: { id: res.data.id },
							        });
						


						}
					} else {
						return false;
					}
				});
			},
			async submit2(id, flag) {
				for (let item of this.list) {
					item.packageId = id
				}
				let obj = {
					stringParam1: this.list
				}

console.log('packageContent/add')
console.log(obj)
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl(
						"/packageContent/add"
					),
					method: "post",
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
					data: obj,
				});
				console.log(res);
				if (res.status) {
					this.$message.success(res.msg);
					if (flag) {
this.$router.push({
          name: "evaluationPackageSetting",
          query: { id: id },
        });
					} else {
						this.$router.go(-1)
					}


				}
			},
			submitNext() {
				this.$refs.form.validate(async (valid) => {
					if (valid) {
if(this.form.listContent){
	delete this.form.listContent
}
for (let item of this.list) {
	if(item.id){
		delete item.id
	}
	if(item.contentType==2&&item.vfTableInfo){
		delete item.vfTableInfo
	}
}
this.form.packageRule = JSON.stringify(this.list)

						const {
							data: res
						} = await this.$httpAes({
							url: this.$httpAes.adornUrl(
								this.form.id ? "/evaluationPackage/update" :
								"/evaluationPackage/add "
							),
							method: "post",
							contentType: 'json',
							headers: {
								'Content-Type': 'application/json',

							},
							data: this.form,
						});
						console.log(res);
						if (res.status) {
							if( this.form.id){
															   this.delSetting()
							}
							this.$router.push({
							          name: "evaluationPackageSetting",
							          query: { id: this.form.id },
							        });
							//this.submit2(res.data.id, true)


						}
					} else {
						return false;
					}
				});
			},
			async delSetting(){
								 let obj = {
									 stringParam1:this.form.id
								 }
								  const { data: res } = await this.$httpAes({
								    url: this.$httpAes.adornUrl(
								      "/packageContent/delete" 
								    ),
								    method: "post",
								  					  			contentType:'json',
								  					  			headers: {
								  					  				'Content-Type': 'application/json',
								  					  			   				
								  					  			},
								    data: obj,
								  });
								    console.log('/packageContent/delete')
								  console.log(res.data)
			},
			delTb(row, index) {
				this.list.splice(index, 1)
			},
			rowDrop() {
				const tbody = document.querySelector('.el-table__body-wrapper tbody')
				Sortable.create(tbody)
			},
			// 		rowDrop() {
			// 		const tbody = document.querySelector('.el-table__body-wrapper tbody')
			// 		const _this = this
			// 		Sortable.create(tbody, {
			// 		onEnd({ newIndex, oldIndex }) {
			// 				console.log( newIndex, oldIndex )
			// 		const currRow = _this.list.splice(oldIndex, 1)[0]
			// 		_this.list.splice(newIndex, 0, currRow)
			// 		console.log( _this.list)

			// 		 for (let index in  _this.list) {
			// 			 _this.list[index].orderNum=''
			// 		 	 _this.list[index].orderNum = parseInt(index) +1
			// 		 }
			// 		_this.$nextTick(()=>{

			// 		  _this.key++;

			// 		})

			// 		},
			// 		})
			// 		},
			getOther(arr) {
				console.log(arr, "val gteOther");

				var that = this
				arr.forEach((item) => {
					let obj = {
						orderNum: that.list.length + 1,
						contentType: that.contentType,
						contentTypeName: that.contentTypeArr[that.contentType - 1],
						relationName: item.tableName,
						relationId: item.id,
						isClick: '',
					}
					for (let obj of that.list) {
						if(obj.relationId ==item.id){
							this.$message.warning('内容选择重复')
							return
						}
					}
					that.list.push(obj)
					that.relationObj = ''
					that.visible = false
				})
			},
			addPac() {
				if (this.contentType == 1) {
					if (this.relationObj != '') {
						let obj = {
							orderNum: this.list.length + 1,
							contentType: this.contentType,
							contentTypeName: this.contentTypeArr[this.contentType - 1],
							relationName: this.relationObj.modelName,
							relationId: this.relationObj.id,
							isClick: '',
						}
						for (let item of this.list) {
							if(item.relationId == this.relationObj.id){
								this.$message.warning('内容选择重复')
								return
							}
						
						}
						this.list.push(obj)
						this.relationObj = ''
						this.visible = false

					} else {
						this.$message.warning('请选择内容名称')
						return
					}
				} else if (this.contentType == 3) {
					if (this.relationObj != '') {
						let obj = {
							orderNum: this.list.length + 1,
							contentType: this.contentType,
							contentTypeName: this.contentTypeArr[this.contentType - 1],
							relationName: this.relationObj.modelName,
							relationId: this.relationObj.id,
							isClick: '',
						}
						for (let item of this.list) {
							if(item.relationId == this.relationObj.id){
								this.$message.warning('内容选择重复')
								return
							}
						
						}
						this.list.push(obj)
						this.relationObj = ''
						this.visible = false
						console.log(this.list)
					} else {
						this.$message.warning('请选择内容名称')
						return
					}
				}

			},

			setCheck(val) {



			},

			handleSuccess2(response, file, fileList) {
				let obj = {
					name: file.name,
					url: response.result.filePath
				}
				this.fileList2 = [obj]
			},
			handleSuccess(response, file, fileList) {
				console.log(response, "response");
				let obj = {
					name: file.name,
					url: response.result.filePath
				}
				this.fileList = [obj]

			},async getList2() {

				let params = {

					stringParam4  :2,
					pageSize: 9999,
					pageNo: 1,
				};

				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl(
						"/diagnosisModel/list"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				if (res.status) {
					this.dataList2 = res.data;
				
				}
			},
			showChoose() {
				console.log('showChooseshowChoose');
				this.qsDialog = true;
				sessionStorage.setItem("type", 1);


				this.$nextTick(() => {

					this.$refs.chooseQs.init();
					// if (this.form.followUpItem.length) {
					//   this.$refs.chooseQs.edit(this.form.followUpItem);
					// }
				});
			},
			beforeUpload(file, fileType) {
				let fileName = file.name;
				let pos = fileName.lastIndexOf(".");
				let lastName = fileName.substring(pos, fileName.length);
				let type = lastName.toLowerCase();
				if (fileType == "1") {

					if (
						type != ".mp4"

					) {
						this.$message.error(
							"上传文件只能是.mp4 格式!"
						);
						this.fileList = [];
						return false;
					}
					return true;
				} else if (fileType == "2") {
					// let isLt2M = file.size / 1024 / 1024 < 50;
					if (type != ".txt") {
						this.$message.error("上传文件只能是.txt格式!");
						this.fileList2 = [];
						return false;
					}

					return true;
				}
			},
			back() {
				this.$router.back();
			},
			beforeUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 10;
				if (!isLt2M) {
					this.$message.error("图片大小不能超过10MB");
				}
			},
			handleUploadSuccess(response, file, fileList) {
				console.log(file);
				this.form.picUrl = file.response.result.filePath;
			},
			async init(id) {
				var that = this
				// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/evaluationPackage/info"),
					method: "post",
					data: {
						stringParam1: id
					},
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				if (res.status) {
					this.form = res.data;
					 if(res.data.listContent&&res.data.listContent[1]&&res.data.listContent[1].length>0){
							this.list = this.form.listContent[1]
					}else{
							this.list =  JSON.parse(this.form.packageRule)
					}
					
					console.log('evaluationPackage/info')
						console.log(this.list)
					for (let item of this.list) {
						item.contentTypeName = that.contentTypeArr[item.contentType - 1]
							
						if(item.contentType==1||item.contentType==3){
							item.relationName = item.diagnosisModel.modelName
						}else{
							item.relationName = item.vfTableInfo.tableName
						}
						
					}
					// this.list.forEach(item = >{
					// 	console.log(item)
					// 	// item.contentTypeName = that.contentTypeArr[item.contentType - 1]
					// })

					// this.checkList = this.form.stimulusForm.split(',')
					// this.listPic(id)
					// this.form.diseaseType = parseInt(this.form.diseaseType)
					// this.dataListLoading = false;
				}

			},
			async getTypeList() {
				let params = {
					stringParam1: "病种",
				};
				// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/dict/list"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				// console.log(res,'res')
				this.typeList = res.data;
			},


			async deleteHousePic(id) {
				let params = {
					stringParam1: id,
				};
				// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/sysPic/deleteHousePic"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});

			},
			async getList() {

				let params = {

stringParam4  :1,
					pageSize: 9999,
					pageNo: 1,
				};

				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl(
						"/diagnosisModel/list"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				if (res.status) {
					this.dataList = res.data;
					console.log('this.dataList')
					console.log(this.dataList)
				}
			},

		},
	};
</script>

<style lang="scss" scoped>
	.public {}

	.tianjia {
		cursor: pointer;
		margin-top: 20px;
		width: 68px;
		height: 28px;
		line-height: 28px;
		text-align: center;
		border-radius: 2px;
		border: 1px solid #A767FD;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #A767FD;
	}
</style>